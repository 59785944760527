import { CompanyModel } from '../../models/companies';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CategoriesHeaderContainer, CompanyCard, CompanyCardLogo, CompanyDetailsContainer, CompanyLinkContainer, CompanyRatingBreakdown, CompanyTopSectionContainer, CompanyTopSectionInnerContainer, CompanyValuesContainer, CompanyValuesInnerContainer, DataContainer, DataSourcesContainer, DataSourcesInnerContainer, OwnedBy, ScoreGraphicContainer, TopButtonsContainer, ValueCategoriesContainer, ValueItem } from './styles';
import { Button } from '../Button';
import { ButtonKind, ButtonSize } from '../Button/styles';
import { ShareIcon } from '../svgs/icons/ShareIcon';
import { theme } from '../../styles/themes';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { useAnalytics } from '../../contexts/analytics-store';
import { SocialModalComponentName, SocialShareModal } from '../modals/SocialShareModal';
import { useNavigate } from 'react-router-dom';
import { getSectorAverage, getUniqueCompanyDataSources, getUniqueSectors } from '../../lib/misc';
import { H2, H2M, H3, H5 } from '../../styles/components/header';
import { IValue, ValuesModel } from '../../models/values';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import { CompanyRatingGraphic } from '../CompanyRatingGraphic';
import { CompanyRatingTag } from '../CompanyRatingTag';
import { FieldWithToolTip } from '../FieldWithToolTip';
import { buildWildfireCashbackUrl } from '../../lib/urlBuilders';
import { useUserSession } from '../../contexts/user';
import { Planet } from '../svgs/graphics/Planet';
import { PeopleGraphic } from '../svgs/graphics/PeopleGraphic';
import { Link } from 'react-router-dom';

interface IProps {
  className?: string;
  company: CompanyModel;
  isReadOnly?: boolean;
  valuesDescription?: JSX.Element;
}

interface TopButtons {
  content: string;
  cta: () => void;
  disabled?: boolean;
  icon?: ReactElement;
  kind: ButtonKind;
  size: ButtonSize;
}

const detail = (label: string, value: any) => (
  <div>
    <div className='detail-label'>{ label }</div>
    <div className='value'>{ value }</div>
  </div>
);

export const CompanyTopSection: React.FC<IProps> = ({
  className = '',
  company,
  isReadOnly = false,
  valuesDescription,
}) => {
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const user = useUserSession();
  const [showShareModal, setShowShareModal] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const [doneLoading, setDoneLoading] = useState(false);
  const [peopleCategoryValues, setPeopleCategoryValues] = useState<IValue[]>([]);
  const [planetCategoryValues, setPlanetCategoryValues] = useState<IValue[]>([]);

  useEffect(() => {
    if (!company._id) return () => null;

    ValuesModel.loadCompanyValues(company._id)
      .then((res) => {
        setPeopleCategoryValues(res.filter(v => v.category.name === 'People'));
        setPlanetCategoryValues(res.filter(v => v.category.name === 'Planet'));
      })
      .catch(() => {
        setErrorLoading(true);
      })
      .finally(() => {
        setDoneLoading(true);
      });
  }, [company._id]);
  
  const sectorAverage = useCallback(() => getSectorAverage(company), [company]);

  const onLinkClick = useCallback(() => {
    analytics.fireEvent('Company_Link_Click');
  }, []);

  const onParentCompanyClick = useCallback(() => {
    analytics.fireEvent('Company_ParentCompany_Click');
  }, []);
  
  const primarySector = useCallback(() => {
    if (!company?.sectors) return null;
    const tierOneSectors = company.sectors.filter(s => s.sector.tier === 1);
    const primarySector = getUniqueSectors(tierOneSectors);

    return primarySector;
  }, [company]);

  // const onSeeCashbackClick = useCallback(() => {
  //   analytics.fireEvent('Company_SeeCashback_Click');
  //   document.getElementById('cashback-offers')
  //     .scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'center',
  //     });
  // }, []);

  const onSeeRelatedCompaniesClick = useCallback(() => {
    analytics.fireEvent('Company_SeeRelatedCompanies_Click');
    const sector = primarySector()[0];
    const relatedCompaniesElement = document.getElementById('related-companies');

    if (relatedCompaniesElement) {
      relatedCompaniesElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    } else {
      navigate(`/browse-and-compare-companies?sector=${sector.sector._id}`);
    }
  }, []);

  const onShareClick = useCallback(() => {
    analytics.fireEvent('Company_Share_Click');
    setShowShareModal(true);
  }, []);

  const onShareModalClose = useCallback(() => {
    setShowShareModal(false);
  }, []);

  const hasWildfireCashback = !!company?.merchant?.integrations?.wildfire?.maxRate?.amount;
  const hasKardCashback = !!company?.merchant?.integrations?.kard?.maxRate?.amount;
  const shouldDisplayCashback = hasKardCashback || hasWildfireCashback;

  const topButtonsList: TopButtons[] = [
    // {
    //   content: 'Cashback Offers',
    //   cta:  onSeeCashbackClick,
    //   disabled: !company.hasCashback || !shouldDisplayCashback,
    //   kind: ButtonKind.Primary,
    //   size: ButtonSize.Small,
    // },
    {
      content: 'Related Companies',
      cta: onSeeRelatedCompaniesClick,
      kind: ButtonKind.GhostMarketing,
      size: ButtonSize.Small,
    },
    {
      content: 'Share',
      cta: onShareClick,
      icon: <ShareIcon fill={ theme.colors.black } />,
      kind: ButtonKind.GhostMarketing,
      size: ButtonSize.Small,
    },
  ];

  const renderTopButtons = useMemo(() => (
    <TopButtonsContainer>
      {
        topButtonsList.map((item, index) => {
          const { content, cta, disabled, icon, kind, size } = item;

          return (
            !disabled ? 
              <Button
                kind={ kind }
                onClick={ cta }
                size={ size }
                key={ index }
              >
                { content }
                { !!icon ? icon : null }
              </Button>
              : 
              null
          );
        })
      }
    </TopButtonsContainer>
  ), [company, shouldDisplayCashback]);

  const renderCard = useMemo(() => {
    if (!!company?.busy || !company || user.authenticating) return null;

    // const wildfireOffer = (() => {
    //   if (!company.merchant?.integrations?.wildfire?.maxRate) return null;
    //   const { type, amount } = company.merchant.integrations.wildfire.maxRate;
      
    //   return type === MerchantRateType.Percent ? `${amount}%` : `$${amount}`;
    // })();

    const url = !!company?.merchant?.integrations?.wildfire && !!user?._id
      ? buildWildfireCashbackUrl(company?.merchant?.integrations?.wildfire, user?._id)
      : company.url;

    const createCompanyLinkContainer = (children: JSX.Element) => (
      <CompanyLinkContainer
        href={ url }
        target='_blank'
        rel='noreferrer'
        onClick={ onLinkClick }
      >
        { children }
      </CompanyLinkContainer>
    );

    const companyLogo = <CompanyCardLogo company={ company } />;
    const companyName = <H3 className='company-name'>{ company.companyName }</H3>;
    // const maxOfferToDisplay = (!user.isLoggedIn || !user.hasKarmaWalletCard) && hasWildfireCashback ? wildfireOffer : company.maxRate;

    return (
      <CompanyCard>
        { isReadOnly ? companyLogo : createCompanyLinkContainer(companyLogo) }
        { isReadOnly ? companyName : createCompanyLinkContainer(companyName) }
        {
          !!company.parentCompany && !company.parentCompany.hidden.status && (
            <OwnedBy
              href={ `/company/${company.parentCompany._id}/${company.parentCompany.slug}` }
              onClick={ onParentCompanyClick }
            >
              { `Owned by ${company.parentCompany.companyName}` }
            </OwnedBy>
          )
        }
        { /* {
          !!company.hasCashback && shouldDisplayCashback && (
            <PillTag kind={ PillTagKind.CashbackGreen } className='cashback'>
              <DollarIcon fill={ theme.colors.dark } />
              <div>Cashback up to { maxOfferToDisplay }</div>
            </PillTag>
          )
        } */ }
      </CompanyCard>
    );
  }, [company, company?.busy, user.authenticating, user.isLoggedIn, shouldDisplayCashback, user.hasKarmaWalletCard]);

  const renderDataSources = useMemo(() => {
    if (company.companyDataSources.length === 0) return null;

    const uniqueDataSources = getUniqueCompanyDataSources(company.companyDataSources);
  
    return (
      <DataSourcesContainer>
        <H2>Data Sources</H2>
        <DataSourcesInnerContainer>
          {
            uniqueDataSources.map(d => (
              <div className='data-source' key={ d.name }>
                <FieldWithToolTip tooltip={ d.name } delay={ 500 }>
                  <img src={ d.logoUrl } alt={ d.name } />
                </FieldWithToolTip>
              </div>
            )) 
          }
        </DataSourcesInnerContainer>
      </DataSourcesContainer>
    );
  }, [company]);

  const renderDetails = useMemo(() => (
    <CompanyDetailsContainer>
      <CompanyRatingBreakdown>
        <div className='company-score'>
          <H2M className='score'>{ company.combinedScore }</H2M>
          <H5 className='score-text'>Company Rating</H5>
        </div>
        <div className='details'>
          <div className='left-details'>
            <div>
              <div className='detail-label'>RATING</div>
              <CompanyRatingTag rating={ company.rating } className='rating' />
            </div>
            { detail('SECTOR', primarySector()[0].sector.name ) }
          </div>
          <div className='left-details right-details'>
            { detail('SECTOR AVG.', sectorAverage()) }
            { detail('CASHBACK', company.hasCashback ? 'No' : 'No' ) /*update once cashback is available again*/ }
          </div>
        </div>
      </CompanyRatingBreakdown>
      <ScoreGraphicContainer>
        <CompanyRatingGraphic score={ company.combinedScore } />
      </ScoreGraphicContainer>
      <div className='data-sources-desktop'>
        { renderDataSources }
      </div>
    </CompanyDetailsContainer>
  ), [company]);

  const renderValueItems = (values: IValue[]) => {
    let content;
     
    if (!doneLoading) {
      content = <LoadingSpinner />;
    } else if (errorLoading) {
      content = (
        <div className='error-text'>
          Error loading company values
        </div>
      );
    } else if (values.length === 0) {
      content = (
        <div className='no-values'>
          <p>No Values determined at this time. <Link to='/rating-system'>Learn&nbsp;More</Link>.</p>
        </div>
      );
    } else {
      content = (
        values.map( v => (
          <ValueItem key={ v.name }>
            <img src={ v.icon } alt={ v.name } aria-hidden={ true } />
            <span className='value-name'>{ v.name }</span>
          </ValueItem>
        ))
      );
    }

    return content;
  };

  const renderCompanyPeopleValues = useMemo(() => {
    const content = renderValueItems(peopleCategoryValues);

    return (
      <CompanyValuesContainer>
        <CategoriesHeaderContainer>
          <PeopleGraphic className='category-icon' />
          <h3>People</h3>
        </CategoriesHeaderContainer>
        <CompanyValuesInnerContainer>
          { content }
        </CompanyValuesInnerContainer>
        { valuesDescription }
      </CompanyValuesContainer>
    );
  }, [peopleCategoryValues, doneLoading, errorLoading]);

  const renderCompanyPlanetValues = useMemo(() => {
    const content = renderValueItems(planetCategoryValues);

    return (
      <CompanyValuesContainer>
        <CategoriesHeaderContainer>
          <Planet className='category-icon' />
          <h3>Planet</h3>
        </CategoriesHeaderContainer>
        <CompanyValuesInnerContainer>
          { content }
        </CompanyValuesInnerContainer>
        { valuesDescription }
      </CompanyValuesContainer>
    );
  }, [planetCategoryValues, doneLoading, errorLoading]);

  return (
    <CompanyTopSectionContainer className={ className }>
      <CompanyTopSectionInnerContainer>
        <SocialShareModal 
          isOpen={ showShareModal }
          onClose={ onShareModalClose }
          componentName={ SocialModalComponentName.Company }
          shareUrl={ window.location.href }
          text={ `Share ${company.companyName}'s Company Overview with your friends, family and colleagues.` }
        />
        { !isReadOnly ? renderTopButtons : null }
        <DataContainer>
          { renderCard }
          { renderDetails }
        </DataContainer>
        <div className='data-sources-mobile'>
          { renderDataSources }
        </div>
        <H2 className='company-values-header'>Company Values</H2>
        <ValueCategoriesContainer>
          { renderCompanyPlanetValues }
          { renderCompanyPeopleValues }
        </ValueCategoriesContainer>
      </CompanyTopSectionInnerContainer>
    </CompanyTopSectionContainer>
  );
};
