import { observer } from 'mobx-react';
import React, { lazy, Suspense, useEffect, useMemo, useRef } from 'react';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { JoinGroupStore } from '../../contexts/join-group';
import { PlaidStore } from '../../contexts/plaid';
import { useUserSession } from '../../contexts/user';
import { BrowseCompanies } from '../../pages/BrowseCompanies';
import { Career } from '../../pages/Career';
import { Careers } from '../../pages/Careers';
import { Company } from '../../pages/Company';
import { CompareCompanies } from '../../pages/CompareCompanies';
import { Donations } from '../../pages/Donations';
import { FAQs } from '../../pages/FAQs';
import { Account as GroupsAccount } from '../../pages/groups/Account';
import { Home } from '../../pages/Home';
import { HowItWorks } from '../../pages/HowItWorks';
import { OffsetEmissions } from '../../pages/OffsetEmissions';
import { PrivatePage } from '../../pages/PrivatePage';
import { SearchResults } from '../../pages/SearchResults';
import { Sources } from '../../pages/Sources';
import { buildBaseKarmaUrl } from '../../lib/urlBuilders';
import { NotFound as GroupsNotFound } from '../../pages/groups/NotFound';
import { UserGroupRole } from '../../models/user-groups';
import { Statements as GroupStatements } from '../../pages/groups/Statements';
import { Dashboard as GroupsDashboard } from '../../pages/groups/Dashboard';
import { useGroup } from '../../contexts/group';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import { BrowseQueryStore } from '../../contexts/browse-query';
import { GroupOffsettingInfo } from '../../pages/GroupOffsettingInfo';
import { NewsletterUnsubscribe } from '../../pages/NewsletterUnsusbscribe';
import { IndustryReport } from '../../pages/IndustryReports';
import { Articles } from '../../pages/Articles';
import { Redirect } from '../../pages/Redirect';
import { OurImpact } from '../../pages/OurImpact';
import { ErrorPage } from '../../pages/ErrorPage';
import { WrappedWithErrorPage } from '../WrappedWithErrorPage';
import { AppHeader } from '../AppHeader';
import { AppLanding } from '../../pages/AppLanding';
import { QrCodeLanding } from '../../pages/QrCodeLanding';
import { PrivacyPolicy } from '../../pages/PrivacyPolicy';
import { DigitalWalletTerms } from '../../pages/DigitalWalletTerms';
import { ApplyForCard } from '../../pages/ApplyForCard';
import { AccountHub } from '../../pages/Account';
import { ContactUs } from '../../pages/ContactUs';
import { KarmaCollective } from '../../pages/KarmaCollective';
import { AboutPage } from '../../pages/About';
import { SessionEndingModal } from '../modals/SessionEndingModal';
import { buildRedirectUrl } from './utils';
const AccountHubRouter = lazy(() => import('./AccountHubRouter'));
import { BlogRedirect } from '../../pages/BlogRedirectPage';

export const MainRouterBase: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userSession = useUserSession();
  const { group } = useGroup();
  const groupsRedirectConfig = useRef({
    authPath: `${buildBaseKarmaUrl('karma')}?signin=true`,
    rolesPath: '/not-found',
    groupsPath: '/not-found',
    groupRolesPath: `${buildBaseKarmaUrl('karma')}/account`,
  }).current;

  useEffect(() => {
    if (!userSession.isLoggedIn) {
      group?.reset();
    }
  }, [userSession.isLoggedIn]);

  useEffect(() => {
    (userSession.initialized ? Promise[userSession.isLoggedIn ? 'resolve' : 'reject']() : userSession.verifySession())
      .then(() => {
        if (location.pathname.includes('/groups') && userSession.initialized && !userSession.isLoggedIn) {
          throw new Error('break');
        }
      })
      .then(() => {
        if (location.pathname.includes('/groups')) {
          return userSession.loadGroups();
        }
      })
      .catch(() => {
        if (location.pathname.includes('/groups')) {
          navigate('/');
        }
      });
  }, [location]);

  const renderAccount = () => (
    <Suspense>
      <PrivatePage
        redirect={ buildRedirectUrl({ signin: 'true' }) }
        element={ <WrappedWithErrorPage Component={ AccountHub } /> }
      />
    </Suspense>
  );

  const renderDashboard = () => (
    <PrivatePage
      requirements={ { group: true } }
      redirect={ groupsRedirectConfig }
      element={ <WrappedWithErrorPage Component={ GroupsDashboard } /> }
    />
  );

  const renderDonations = () => (
    <PrivatePage
      redirect={ buildRedirectUrl({ signin: 'true' }) }
      element={ <WrappedWithErrorPage Component={ Donations } /> }
    />
  );

  const renderGroupsAccount = () => (
    <PrivatePage
      requirements={ { group: true, groupRoles: [UserGroupRole.Admin, UserGroupRole.SuperAdmin, UserGroupRole.Owner] } }
      redirect={ groupsRedirectConfig }
      element={ <WrappedWithErrorPage Component={ GroupsAccount } /> }
    />
  );

  const renderOffsetEmissions = () => (
    <PrivatePage 
      redirect={ buildRedirectUrl({ signin: 'true', redirectTo: '/offset-emissions' }) }
      element={ <WrappedWithErrorPage Component={ OffsetEmissions } /> }
    />
  );

  const renderStatements = () => (
    <PrivatePage
      requirements={ { group: true, groupRoles: [UserGroupRole.SuperAdmin, UserGroupRole.Owner] } }
      redirect={ groupsRedirectConfig }
      element={ <WrappedWithErrorPage Component={ GroupStatements } /> }
    />
  );

  const browseCompanies = useMemo(() => (
    <BrowseQueryStore>
      <BrowseCompanies />
    </BrowseQueryStore>
  ), []);

  const renderAppHeader = () => {
    if (location.pathname.includes('/account')) return null;
    return <AppHeader />;
  };

  if (location.pathname.includes('/groups') && !userSession.isLoggedIn && (!userSession.initialized || userSession.authenticating)) return <LoadingSpinner />;

  return (
    <JoinGroupStore>
      <PlaidStore>
        { renderAppHeader() }
        <Routes>
          <Route path='/' element={ <Home /> } />
          <Route path='/about' element={ <WrappedWithErrorPage Component={ AboutPage } /> } />
          <Route path='/account/*' element={ renderAccount() }>
            <Route index element={ <Navigate to='dashboard' /> } />
            <Route path='*' element={ <Suspense fallback={ <LoadingSpinner /> }><AccountHubRouter /></Suspense> } />
          </Route>
          <Route path='/app' element={ <WrappedWithErrorPage Component={ AppLanding } /> } />
          <Route path='/donegood-app' element={ <WrappedWithErrorPage Component={ AppLanding } componentProps={ { isDoneGood: true } } /> } />
          <Route path='/browse-and-compare-companies' element={ browseCompanies } />
          <Route path='/get-karma-card' element={ <WrappedWithErrorPage Component={ QrCodeLanding } /> } />
          <Route path='/careers/:jobId' element={ <WrappedWithErrorPage Component={ Career } /> } />
          <Route path='/careers' element={ <WrappedWithErrorPage Component={ Careers } /> } />
          <Route path='/company/:companyId'>
            <Route index={ true } element={ <WrappedWithErrorPage Component={ Company } /> } />
            <Route path=':companySlug' element={ <WrappedWithErrorPage Component={ Company } /> } />
          </Route>
          <Route path='/compare-companies' element={ <WrappedWithErrorPage Component={ CompareCompanies } /> } />
          <Route path='/contact-us' element={ <WrappedWithErrorPage Component={ ContactUs } /> } />
          <Route path='/donations' element={ renderDonations() } />
          <Route path='/digital-wallet-terms-and-conditions' element={ <DigitalWalletTerms /> } />
          { /* <Route path='/employers' element={ <WrappedWithErrorPage Component={ EmployerPage } /> } /> */ }
          <Route path='/error' element={ <ErrorPage /> } />
          <Route path='/faqs' element={ <WrappedWithErrorPage Component={ FAQs } /> } />
          <Route path='/group-offsetting-program' element={ <WrappedWithErrorPage Component={ GroupOffsettingInfo } /> } />
          <Route path='/groups'>
            <Route index={ true } element={ <WrappedWithErrorPage Component={ GroupsNotFound } /> } />
            <Route path='/groups/not-found' element={ <WrappedWithErrorPage Component={ GroupsNotFound } /> } />
            <Route path='/groups/account/:groupId' element={ renderGroupsAccount() } />
            <Route path='/groups/statements/:groupId' element={ renderStatements() } />
            <Route path='/groups/:groupId' element={ renderDashboard() } />
            <Route path='/groups/*' element={ <WrappedWithErrorPage Component={ GroupsNotFound } /> } />
          </Route>
          <Route path='industry-reports' element={ <WrappedWithErrorPage Component={ Articles } /> } />
          <Route path='/industry-report/:articleType/:articleCompany/:articleTitle/:articleId' element={ <WrappedWithErrorPage Component={ IndustryReport } /> } />
          <Route path='/karma-collective' element={ <WrappedWithErrorPage Component={ KarmaCollective } /> } />
          <Route path='/newsletter-unsubscribe' element={ <WrappedWithErrorPage Component={ NewsletterUnsubscribe } /> } />
          <Route path='/offset-emissions' element={ renderOffsetEmissions() } />
          <Route path='/our-impact' element={ <OurImpact /> } />
          { /* <Route path='/partnerships' element={ <WrappedWithErrorPage Component={ Partnerships } /> } /> */ }
          <Route path='/privacy-policy' element={ <PrivacyPolicy /> } />
          <Route path='/rating-system' element={ <WrappedWithErrorPage Component={ HowItWorks } /> } />
          <Route path='/rating-system2' element={ <WrappedWithErrorPage Component={ FAQs } /> } />
          <Route path='/redirect/:type/:companyId' element={ <Redirect /> } />
          <Route path='/blog-redirect' element={ <BlogRedirect /> } />
          <Route path='/search' element={ <WrappedWithErrorPage Component={ SearchResults } /> } />
          <Route path='/sources' element={ <WrappedWithErrorPage Component={ Sources } /> } />
          <Route path='/apply' element={ <ApplyForCard /> } />
          <Route path='/donegood-apply' element={ <ApplyForCard isDoneGood /> } />
          <Route path='*' element={ <Navigate to='/' /> } />
        </Routes>
      </PlaidStore>
      { userSession.sessionIsIdle && <SessionEndingModal isOpen={ userSession.sessionIsIdle } /> }
    </JoinGroupStore>
  );
};

export const MainRouter = observer(MainRouterBase);
