import React from 'react';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { DoneGoodIcon } from '../svgs/logos/DoneGoodIcon';

export enum NavLinkType {
  BrowseCompanies = 'browse-companies',
  Research = 'research',
  CompareCompanies = 'compare',
  RatingSystem = 'rating-system',
  OurImpact = 'our-impact',
  ShowMore = 'show-more',
  CompanyRatings = 'company-ratings',
  NewsInsights = 'news-insights',
  ShopNow = 'shop-now',
  BecomeAMember = 'become-a-member',
  KarmaCollective = 'karma-collective',
  EmployeeBenefits = 'employee-benefits',
  BanksCreditUnions = 'banks-credit-unions',
  OtherPartnerships = 'other-partnerships',
}

export interface IHeaderMenu {
  id: string;
  text: string;
  subMenu: ISubMenu[];
}

export interface ISubMenu {
  id: NavLinkType;
  text: string;
  altText?: string;
  subText: string;
  altSubText?: string;
  pathname?: string;
  altPathname?: string;
  icon?: string | ReactElement;
  onBlur?: () => void;
  isLive?: boolean;
  externalLink?: boolean;
}

export const headerMenus: IHeaderMenu[] = [
  { 
    id: 'explore-data',
    text: 'Explore Data',
    subMenu: [
      {
        id: NavLinkType.CompanyRatings,
        text: 'Company Ratings',
        subText: 'Browse & Compare',
        pathname: '/browse-and-compare-companies',
        isLive: true,
      },
      {
        id: NavLinkType.NewsInsights,
        text: 'News & Insights',
        subText: 'Blog',
        pathname: 'https://karmawallet.io/blog',
        isLive: true,
        externalLink: true,
      },
    ],
  },
  { 
    id: 'marketplace',
    text: 'Marketplace',
    subMenu: [
      {
        id: NavLinkType.ShopNow,
        text: 'Shop Now',
        // altSubText: '10% Cashback + FREE Shipping',
        subText: '6000+ Eco-Friendly Products',
        pathname: 'https://donegood.co/',
        icon: <DoneGoodIcon />,
        isLive: true,
        externalLink: true,
      },
      // {
      //   id: NavLinkType.BecomeAMember,
      //   text: 'Become a Member',
      //   subText: '10% Cashback + FREE Shipping',
      //   pathname: '/apply',
      //   icon: EmptyKWCard,
      //   isLive: true,
      // },
    ],
  },
  { 
    id: 'partnerships',
    text: 'Partnerships',
    subMenu: [
      {
        id: NavLinkType.KarmaCollective,
        text: 'Karma Collective',
        subText: 'Join 140+ Brands',
        pathname: '/karma-collective',
        isLive: true,
      },
      // {
      //   id: NavLinkType.EmployeeBenefits,
      //   text: 'Employee Benefits',
      //   subText: '70% Adoption',
      //   pathname: '/employers',
      //   isLive: true,
      // },
      // {
      //   id: NavLinkType.BanksCreditUnions,
      //   text: 'Banks & Credit Unions',
      //   subText: '2X Gen Z & Millennials',
      //   pathname: '/banks-credit-unions',
      //   isLive: false,
      // },
      // {
      //   id: NavLinkType.OtherPartnerships,
      //   text: 'Other Partnerships',
      //   subText: 'Learn More',
      //   pathname: '/partnerships',
      //   isLive: true,
      // },
    ],
  },
];
