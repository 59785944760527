import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useAnalytics } from '../../contexts/analytics-store';
import { useBrowseQuery } from '../../contexts/browse-query';
import { SectorsModel } from '../../models/sectors';
import { Button } from '../Button';
import { ButtonKind } from '../Button/styles';
import { KarmaScoreFilter } from '../KarmaScoreFilter';
import { FilterPillPicker, IPillItem } from '../FilterPillPicker';
import { FilterCompaniesModalContainer, Footer, Header, ModalContent, PillFilterSection, RatingSection, UNSDGFilterSection } from './styles';
import { useAppData } from '../../contexts/app';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../styles/themes';
import { UNSDGsModel } from '../../models/unsdgs';
import { ValuesModel } from '../../models/values';
import { H3 } from '../../styles/components/header';
import { UNSDGsFilterPicker } from '../UNSDGsFilterPicker';

interface IProps extends IThemeProps {
  className?: string;
  isOpen: boolean;
  onClose(): void;
  onFilterModalUpdate(): void;
}

const FilterCompaniesModalBase: React.FC<IProps> = ({
  className = '',
  isOpen,
  onClose,
  onFilterModalUpdate,
  // theme,
}) => {
  const requestQueryModel = useBrowseQuery();
  const valuesModel = useRef(new ValuesModel()).current;
  const analytics = useAnalytics();
  const appData = useAppData();
  const sectorsModel = useRef(new SectorsModel({ limit: 999 })).current;
  const unsdgsModel = useRef(new UNSDGsModel()).current;

  const loadSectors = useCallback(async () => {
    try { 
      await sectorsModel.sectors.loadMore({ tier: 1 });
    } catch (e) {
      analytics.fireEvent('BC_Error_Loading_Sectors');
    }
  }, []);

  const loadUnsdgs = useCallback(async () => {
    try {
      await unsdgsModel.load();
    } catch (e) {
      analytics.fireEvent('BC_Error_Loading_UNSDGs');
    }
  }, []);

  const loadValues = useCallback(async () => {
    try {
      await valuesModel.load();
    } catch (e) {
      analytics.fireEvent('BC_Error_Loading_Values');
    }
  }, []);

  useEffect(() => {
    loadSectors();
    loadUnsdgs();
    loadValues();
  }, []);

  const onValueClick = useCallback((value: string) => () => {
    requestQueryModel.updateValues(value);
    analytics.fireEvent('BC_FilterModal_Value_Click');
  }, [requestQueryModel.updateValues]);

  const onClearAll = useCallback(() => {
    requestQueryModel.clearSectors();
    requestQueryModel.clearRatings();
    requestQueryModel.clearCashback();
    requestQueryModel.clearUnsdgs();
    requestQueryModel.clearValues();
    requestQueryModel.toggleClear();
    analytics.fireEvent('BC_FilterModal_ClearAll');
  }, []);

  // const onCashbackClick = useCallback(() => {
  //   analytics.fireEvent('BC_FilterModal_Cashback_Click');
    
  //   if (!requestQueryModel.cashbackOnly) {
  //     if (!requestQueryModel.ratings.includes(Ratings.Neutral)) requestQueryModel.updateRatings(Ratings.Neutral);
  //     if (!requestQueryModel.ratings.includes(Ratings.Positive)) requestQueryModel.updateRatings(Ratings.Positive);
  //     if (requestQueryModel.ratings.includes(Ratings.Negative)) requestQueryModel.updateRatings(Ratings.Negative);
  //   } else {
  //     requestQueryModel.clearRatings();
  //   }

  //   requestQueryModel.setCashbackOnly(!requestQueryModel.cashbackOnly);
  // }, [requestQueryModel.cashbackOnly, requestQueryModel.ratings]);

  const ratingSection = useMemo(() => (
    <RatingSection>
      <H3 className='filter-header'>Company Rating</H3>
      <KarmaScoreFilter />
    </RatingSection>
  ), []);

  // const cashbackSection = useMemo(() => (
  //   <CashbackSectionContainer>
  //     <H3 className='filter-header'>Offerings</H3>
  //     <CashbackCheckbox 
  //       className='cashback-checkbox'
  //       label='Cashback Offers'
  //       checked={ requestQueryModel.cashbackOnly }
  //       onChange={ onCashbackClick }
  //       checkColor={ theme.colors.darkGray3 }
  //       id='cashback-checkbox'
  //     />
  //   </CashbackSectionContainer>
  // ), [requestQueryModel.cashbackOnly, onCashbackClick]);

  const onUnsdgClick = useCallback((unsdg: string) => () => {
    requestQueryModel.updateUnsdgs(unsdg);
    analytics.fireEvent('BC_FilterModal_UNSDG_Click');
  }, [requestQueryModel.updateUnsdgs]);

  const unsdgsPicker = useMemo(() => {
    if (!unsdgsModel.unsdgs.length) return null;

    const unsdgs = unsdgsModel.unsdgs.map(unsdg => ({
      id: unsdg._id,
      onClick: onUnsdgClick(unsdg._id),
      selected: requestQueryModel.unsdgs.includes(unsdg._id),
      logos: unsdg.logos,
      name: unsdg.title,
    }));

    return (
      <UNSDGFilterSection>
        <H3 className='filter-header'>UN SDGs</H3>
        <UNSDGsFilterPicker unsdgItems={ unsdgs } />
      </UNSDGFilterSection>
    );
  }, [unsdgsModel.unsdgs, requestQueryModel.unsdgs, onUnsdgClick]);

  const valuesPillPicker = useMemo(() => {
    if (!valuesModel.values.length) return null;

    const items: IPillItem[] = valuesModel.values.map(v => ({
      id: v._id,
      label: v.name,
      icon: v.icon,
      onClick: onValueClick(v._id),
      selected: requestQueryModel.values.includes(v._id),
    }));

    return (
      <PillFilterSection>
        <H3 className='filter-header'>Values</H3>
        <FilterPillPicker
          items={ items }
        />
      </PillFilterSection>
    );
  }, [valuesModel.values, requestQueryModel.values, onValueClick]);

  const footer = useMemo(() => (
    <Footer>
      <Button
        kind={ ButtonKind.Blank }
        onClick={ onClearAll }
        className='clear-all-button'
      >
        Clear All
      </Button>
      <Button
        kind={ ButtonKind.Primary }
        onClick={ onFilterModalUpdate }
        className='close-and-update-button'
      > 
        Show Companies
      </Button>
    </Footer>
  ), []);

  return (
    <FilterCompaniesModalContainer
      className={ className }
      onClose={ onClose }
      isOpen={ isOpen }
      windowHeight={ appData.windowVisualViewportHeight }
    > 
      <Header>
        <p className='header-text'>Filters</p>
      </Header>
      <ModalContent>
        { ratingSection }
        { /* { cashbackSection } */ }
        { valuesPillPicker }
        { unsdgsPicker }
      </ModalContent>
      { footer }
    </FilterCompaniesModalContainer>
  );
};

const FilterCompaniesModalObserver = observer(FilterCompaniesModalBase);
export const FilterCompaniesModal = withTheme(FilterCompaniesModalObserver);
